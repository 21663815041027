h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

.reactour__popover div button svg {
  color: white !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Cells that are in the process of being selected */
.table-drag-select td.cell-being-selected {
  border: 5px grey solid;
}

.table-drag-select td {
  padding: 0;
  border: 4px solid transparent;
}

.flex-container {
  display: flex;
}

.flex-item1 {
  flex: 1;
}

.flex-item2 {
  flex: 4;
}

.flex-item1:first-child {
  margin-right: 20px;
}

/* .flex-item2:first-child {
  margin-right: 20px;
}  */
