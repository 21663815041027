.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.modal-content {
  min-height: 200px;
  padding: 25px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border-radius: 0.375rem;
}
.markdown h1 {
  font-size: 2em;
  font-weight: bold;
  margin: 0.67em 0;
}

.markdown h2 {
  font-size: 1.75em;
  font-weight: bold;
  margin: 0.75em 0;
}

.markdown h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0;
}

.markdown h4 {
  font-size: 1.25em;
  font-weight: bold;
  margin: 1.12em 0;
}

.markdown h5 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.5em 0;
}

.markdown h6 {
  font-size: 0.875em;
  font-weight: bold;
  margin: 1.67em 0;
}

.markdown ul {
  list-style-type: disc;
  padding-left: 1.5em;
  margin: 1em 0;
  margin-top: 0px;
}

.markdown ol {
  list-style-type: decimal;
  padding-left: 2em;
  margin: 1em 0;
  margin-top: 0px;
}

.markdown li {
  font-size: 1em;
  margin-bottom: 0.5em;
  margin-top: 0px;
}

.markdown del,
.markdown s {
  text-decoration: line-through;
}

.markdown a {
  color: #007bff;
  text-decoration: underline;
}

.markdown a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.markdown blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1em;
  color: #666;
  margin: 1em 0;
  font-style: italic;
}

.markdown img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em auto;
}

.markdown table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.markdown th,
.markdown td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.markdown tr:nth-child(even) {
  background-color: #f9f9f9;
}
